import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const API_PACKAGE_URL = `${environment.apiUrl}/packages`;

@Injectable({
  providedIn: 'root',
})
export class PackageHttpService {
  constructor(private http: HttpClient) {}

  getPackageByCertType(certType: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_PACKAGE_URL}/findByCertType?certType=${certType}`
    );
  }

  getAllPackage(): Observable<any> {
    const payload = {};
    return this.http.post<any>(`${API_PACKAGE_URL}/get-packages`, payload);
  }

  getPackage(package_code: string): Observable<any> {
    return this.http.get<any>(
      `${API_PACKAGE_URL}/get-package?package_code=${package_code}`
    );
  }

  searchPackage(cert_type: string, status_: string): Observable<any> {
    let status = null;
    if (status_ === 'true') status = true;
    else if (status_ === 'false') status = false;
    const payload = { cert_type, status };
    return this.http.post<any>(`${API_PACKAGE_URL}/get-packages`, payload);
  }

  deletePackage(package_code: string): Observable<any> {
    return this.http.delete<any>(
      `${API_PACKAGE_URL}/delete-package?package_code=${package_code}`
    );
  }

  addPackage(payload: any): Observable<any> {
    return this.http.post<any>(`${API_PACKAGE_URL}/add-package`, payload);
  }

  updatePackage(payload: any): Observable<any> {
    return this.http.post<any>(`${API_PACKAGE_URL}/update-package`, payload);
  }
}
