import { AuthModel } from './auth.model';

export class UserModel extends AuthModel {
  id: string | null;
  name: string | null;
  email: string | null;
  username: string | null;
  password: string | null;
  roles: string | null;
  unitId: string | null;
  agencyId: string | null;
  status: string | null;
  phoneNumber: string | null;
  lastLoginAt: string | null;
  loginFailNum: number | null;
  isLockLogin: boolean | null;
  lockReason: string | null;
  createdBy: string | null;
  createdTime: string | null;
  updatedBy: string | null;
  updatedTime: string | null;
  lockedTime: string | null;
  imageUrl: string | null;

  setUser(_user: unknown) {
    const user = _user as UserModel;
    this.id = user.id;
    this.name = user.name || '';
    this.email = user.email || '';
    this.username = user.username || '';
    this.password = user.password || '';
    this.roles = user.roles || '';
    this.unitId = user.unitId || '';
    this.agencyId = user.agencyId || '';
    this.status = user.status || '';
    this.phoneNumber = user.phoneNumber || '';
    this.lastLoginAt = user.lastLoginAt || '';
    this.loginFailNum = user.loginFailNum || null;
    this.isLockLogin = user.isLockLogin || null;
    this.lockReason = user.lockReason || '';
    this.createdBy = user.createdBy || '';
    this.createdTime = user.createdTime || '';
    this.updatedBy = user.updatedBy || '';
    this.updatedTime = user.updatedTime || '';
    this.lockedTime = user.lockedTime || '';
    this.imageUrl = user.imageUrl || './assets/media/users/default.jpg';
  }

  constructor() {
    super();
    this.id = null;
    this.name = null;
    this.email = null;
    this.username = null;
    this.password = null;
    this.roles = null;
    this.unitId = null;
    this.agencyId = null;
    this.status = null;
    this.phoneNumber = null;
    this.lastLoginAt = null;
    this.loginFailNum = null;
    this.isLockLogin = null;
    this.lockReason = null;
    this.createdBy = null;
    this.createdTime = null;
    this.updatedBy = null;
    this.updatedTime = null;
    this.lockedTime = null;
    this.imageUrl = null;
  }
}
