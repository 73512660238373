interface Error {
  field: string;
  message: string;
}

export class ErrorResponse {
  statusCode: string;
  message: string;
  errors: Error[];

  constructor() {
    this.statusCode = '';
    this.message = '';
    this.errors = [];
  }
}
