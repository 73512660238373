<div class="card-container">
  <form
    nz-form
    class="flex flex-col"
    consFormFieldValidation
    [formGroup]="changeUserInfo"
  >
    <div class="flex flex-col ant-card-body">
      <span class="text-[#034694] font-semibold mb-2">Thông tin cơ bản</span>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Tên<span style="color: red; margin-left: 2px"
              >*</span
            ></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <input type="text" nz-input formControlName="name" />
            <ng-container *ngIf="checkIsValid('name')">
              <span
                *ngIf="
                  getFormControlInFormGroup('name').errors &&
                  getFormControlInFormGroup('name').errors?.required
                "
                class="app-error"
              >
                Tên người dùng không được để trống
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('name').errors &&
                  getFormControlInFormGroup('name').errors?.maxlength
                "
                class="app-error"
              >
                Tên người dùng không được lớn hơn 255 ký tự
              </span>
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Số điện thoại<span style="color: red; margin-left: 2px"
              >*</span
            ></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <input type="text" nz-input formControlName="phone" />
            <ng-container *ngIf="checkIsValid('phone')">
              <span
                *ngIf="getFormControlInFormGroup('phone').errors?.pattern"
                class="app-error"
              >
                Số điện thoại không hợp lệ (Bắt đầu bằng 0 hoặc +84, tiếp theo
                là 9 số)
              </span>
              <span
                *ngIf="getFormControlInFormGroup('phone').errors?.required"
                class="app-error"
              >
                Số điện thoại không được để trống
              </span>
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Email<span style="color: red; margin-left: 2px"
              >*</span
            ></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <input type="text" nz-input formControlName="email" />
            <ng-container *ngIf="checkIsValid('email')">
              <span
                *ngIf="getFormControlInFormGroup('email').errors?.email"
                class="app-error"
              >
                Định dạng email không chính xác
              </span>
              <span
                *ngIf="getFormControlInFormGroup('email').errors?.maxlength"
                class="app-error"
              >
                Email không được lớn hơn 255 ký tự
              </span>
              <span
                *ngIf="getFormControlInFormGroup('email').errors?.required"
                class="app-error"
              >
                Email không được để trống
              </span>
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9">Tên đăng nhập TMS</nz-form-label>
          <nz-form-control [nzSpan]="7">
            <input type="text" nz-input formControlName="username" />
            <ng-container *ngIf="checkIsValid('username')">
              <span
                *ngIf="
                  getFormControlInFormGroup('username').errors &&
                  getFormControlInFormGroup('username').errors?.required
                "
                class="app-error"
              >
                Tên đăng nhập TMS không được để trống
              </span>
              <span
                *ngIf="getFormControlInFormGroup('username').errors?.maxlength"
                class="app-error"
              >
                Tên đăng nhập TMS không được lớn hơn 255 ký tự
              </span>
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div class="flex flex-col ant-card-body">
      <span class="text-[#034694] font-semibold mb-2">Thay đổi mật khẩu</span>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Mật khẩu cũ
            <span style="color: red; margin-left: 2px">*</span></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <input
              [type]="showPasswordOld ? 'text' : 'password'"
              nz-input
              formControlName="passwordOld"
              [ngClass]="{
                  'border border-rose-600': checkIsValid('passwordOld'),
                }"
            />
            <div class="absolute inset-y-0 right-0 rounded-lg pr-3 pt-2">
              <i
                class="cursor-pointer text-gray-400 fas"
                [ngClass]="{
                  'fa-eye-slash': !showPasswordOld,
                  'fa-eye': showPasswordOld
                }"
                (click)="toggleShowPassword('passwordOld')"
              ></i>
            </div>
            <ng-container *ngIf="checkIsValid('passwordOld')">
              <span
                *ngIf="
                  getFormControlInFormGroup('passwordOld').errors &&
                  getFormControlInFormGroup('passwordOld').errors?.required
                "
                class="app-error"
              >
                Mật khẩu cũ không được để trống
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('passwordOld').errors?.minlength
                "
                class="app-error"
              >
                Mật khẩu cũ không được bé hơn 6 ký tự
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('passwordOld').errors?.maxlength
                "
                class="app-error"
              >
                Mật khẩu cũ không được lớn hơn 255 ký tự
              </span>
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Mật khẩu mới
            <span style="color: red; margin-left: 2px">*</span></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <input
              [type]="showPasswordNew ? 'text' : 'password'"
              nz-input
              formControlName="passwordNew"
              [ngClass]="{
                  'border border-rose-600': checkIsValid('passwordNew'),
                }"
            />
            <div class="absolute inset-y-0 right-0 rounded-lg pr-3 pt-2">
              <i
                class="cursor-pointer text-gray-400 fas"
                [ngClass]="{
                  'fa-eye-slash': !showPasswordNew,
                  'fa-eye': showPasswordNew
                }"
                (click)="toggleShowPassword('passwordNew')"
              ></i>
            </div>
            <ng-container *ngIf="checkIsValid('passwordNew')">
              <span
                *ngIf="
                  getFormControlInFormGroup('passwordNew').errors &&
                  getFormControlInFormGroup('passwordNew').errors?.required
                "
                class="app-error"
              >
                Mật khẩu mới không được để trống
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('passwordNew').errors?.minlength
                "
                class="app-error"
              >
                Mật khẩu mới không được bé hơn 6 ký tự
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('passwordNew').errors?.maxlength
                "
                class="app-error"
              >
                Mật khẩu mới không được lớn hơn 255 ký tự
              </span>
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Nhập lại mật khẩu
            <span style="color: red; margin-left: 2px">*</span></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <input
              [type]="showPasswordNewRetype ? 'text' : 'password'"
              nz-input
              formControlName="passwordNewRetype"
              [ngClass]="{
                  'border border-rose-600': checkIsValid('passwordNewRetype'),
                }"
            />
            <div class="absolute inset-y-0 right-0 rounded-lg pr-3 pt-2">
              <i
                class="cursor-pointer text-gray-400 fas"
                [ngClass]="{
                  'fa-eye-slash': !showPasswordNewRetype,
                  'fa-eye': showPasswordNewRetype
                }"
                (click)="toggleShowPassword('passwordNewRetype')"
              ></i>
            </div>
            <ng-container *ngIf="checkIsValid('passwordNewRetype')">
              <span
                *ngIf="
                  getFormControlInFormGroup('passwordNewRetype').errors &&
                  getFormControlInFormGroup('passwordNewRetype').errors
                    ?.required
                "
                class="app-error"
              >
                Nhập lại mật khẩu mới không được để trống
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('passwordNewRetype').errors
                    ?.minlength
                "
                class="app-error"
              >
                Nhập lại mật khẩu mới không được bé hơn 6 ký tự
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('passwordNewRetype').errors
                    ?.maxlength
                "
                class="app-error"
              >
                Nhập lại mật khẩu mới không được lớn hơn 255 ký tự
              </span>
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div class="flex flex-col ant-card-body">
      <span class="text-[#034694] font-semibold mb-2"
        >Cấu hình gửi email
        <label
          class="custom-checkbox ml-2"
          nz-checkbox
          formControlName="configEmail"
          (ngModelChange)="changeConfigEmail()"
        ></label>
      </span>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Dịch vụ mail server
            <span style="color: red; margin-left: 2px">*</span></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <nz-input-group>
              <input
                class="rounded"
                nz-input
                [nzAutocomplete]="agencyAuto"
                formControlName="emailServer"
                [ngClass]="{
                    'border border-rose-600': checkIsValid('emailServer'),
                  }"
              />
              <div class="absolute inset-y-0 right-0 rounded-lg pr-3 pt-1">
                <i class="cursor-pointer text-gray-400 fas fa-sort-down"></i>
              </div>
              <ng-container *ngIf="checkIsValid('emailServer')">
                <span
                  *ngIf="
                    getFormControlInFormGroup('emailServer').errors &&
                    getFormControlInFormGroup('emailServer').errors?.required
                  "
                  class="app-error"
                >
                  Server không được để trống
                </span>
                <span
                  *ngIf="
                    getFormControlInFormGroup('emailServer').errors?.maxlength
                  "
                  class="app-error"
                >
                  Server không được lớn hơn 255 ký tự
                </span>
              </ng-container>
            </nz-input-group>
            <nz-autocomplete #agencyAuto>
              <nz-auto-option
                *ngFor="let server of listMailServer; let i = index"
                [nzValue]="server.id"
                [nzLabel]="server.label"
              >
                {{ server.label }}
              </nz-auto-option>
            </nz-autocomplete>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Địa chỉ máy chủ email server (SMPT)
            <span style="color: red; margin-left: 2px">*</span></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <input
              type="text"
              nz-input
              formControlName="urlEmailServer"
              [ngClass]="{
                  'border border-rose-600': checkIsValid('urlEmailServer'),
                }"
            />
            <ng-container *ngIf="checkIsValid('urlEmailServer')">
              <span
                *ngIf="
                  getFormControlInFormGroup('urlEmailServer').errors &&
                  getFormControlInFormGroup('urlEmailServer').errors?.required
                "
                class="app-error"
              >
                Địa chỉ máy chủ email server (SMPT) không được để trống
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('urlEmailServer').errors?.maxlength
                "
                class="app-error"
              >
                Địa chỉ máy chủ email server (SMPT) không được lớn hơn 255 ký tự
              </span>
            </ng-container>
          </nz-form-control>
          <label class="ml-4 mt-2" nz-checkbox formControlName="useSSL"
            >SSL</label
          >
        </nz-form-item>
      </div>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Cổng kết nối
            <span style="color: red; margin-left: 2px">*</span></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <input
              type="text"
              nz-input
              formControlName="connectionPort"
              [ngClass]="{
                  'border border-rose-600': checkIsValid('connectionPort'),
                }"
            />
            <ng-container *ngIf="checkIsValid('connectionPort')">
              <span
                *ngIf="
                  getFormControlInFormGroup('connectionPort').errors &&
                  getFormControlInFormGroup('connectionPort').errors?.required
                "
                class="app-error"
              >
                Cổng kết nối không được để trống
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('connectionPort').errors?.maxlength
                "
                class="app-error"
              >
                Cổng kết nối không được lớn hơn 255 ký tự
              </span>
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Địa chỉ email gửi đi
            <span style="color: red; margin-left: 2px">*</span></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <input
              type="text"
              nz-input
              formControlName="fromEmailUser"
              [ngClass]="{
                  'border border-rose-600': checkIsValid('fromEmailUser'),
                }"
            />
            <ng-container *ngIf="checkIsValid('fromEmailUser')">
              <span
                *ngIf="
                  getFormControlInFormGroup('fromEmailUser').errors &&
                  getFormControlInFormGroup('fromEmailUser').errors?.required
                "
                class="app-error"
              >
                Địa chỉ email gửi đi không được để trống
              </span>
              <span
                *ngIf="getFormControlInFormGroup('fromEmailUser').errors?.email"
                class="app-error"
              >
                Định dạng email không chính xác
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('fromEmailUser').errors?.maxlength
                "
                class="app-error"
              >
                Địa chỉ email gửi đi không được lớn hơn 255 ký tự
              </span>
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Mật khẩu email
            <span style="color: red; margin-left: 2px">*</span></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <input
              [type]="showFromEmailPass ? 'text' : 'password'"
              nz-input
              formControlName="fromEmailPass"
              [ngClass]="{
                  'border border-rose-600': checkIsValid('fromEmailPass'),
                }"
            />
            <div class="absolute inset-y-0 right-0 rounded-lg pr-3 pt-2">
              <i
                class="cursor-pointer text-gray-400 fas"
                [ngClass]="{
                  'fa-eye-slash': !showFromEmailPass,
                  'fa-eye': showFromEmailPass
                }"
                (click)="toggleShowPassword('fromEmailPass')"
              ></i>
            </div>
            <ng-container *ngIf="checkIsValid('fromEmailPass')">
              <span
                *ngIf="
                  getFormControlInFormGroup('fromEmailPass').errors &&
                  getFormControlInFormGroup('fromEmailPass').errors?.required
                "
                class="app-error"
              >
                Mật khẩu email không được để trống
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('fromEmailPass').errors?.minlength
                "
                class="app-error"
              >
                Mật khẩu email không được bé hơn 6 ký tự
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('fromEmailPass').errors?.maxlength
                "
                class="app-error"
              >
                Mật khẩu email không được lớn hơn 255 ký tự
              </span>
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="flex flex-row">
        <nz-form-item class="flex form-item-center">
          <nz-form-label [nzSpan]="9"
            >Nhập lại mật khẩu
            <span style="color: red; margin-left: 2px">*</span></nz-form-label
          >
          <nz-form-control [nzSpan]="7">
            <input
              [type]="showFromEmailPassRetype ? 'text' : 'password'"
              nz-input
              formControlName="fromEmailPassRetype"
              [ngClass]="{
                  'border border-rose-600': checkIsValid('fromEmailPassRetype'),
                }"
            />
            <div class="absolute inset-y-0 right-0 rounded-lg pr-3 pt-2">
              <i
                class="cursor-pointer text-gray-400 fas"
                [ngClass]="{
                  'fa-eye-slash': !showFromEmailPassRetype,
                  'fa-eye': showFromEmailPassRetype
                }"
                (click)="toggleShowPassword('fromEmailPassRetype')"
              ></i>
            </div>
            <ng-container *ngIf="checkIsValid('fromEmailPassRetype')">
              <span
                *ngIf="
                  getFormControlInFormGroup('fromEmailPassRetype').errors &&
                  getFormControlInFormGroup('fromEmailPassRetype').errors
                    ?.required
                "
                class="app-error"
              >
                Nhập lại mật khẩu email không được để trống
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('fromEmailPassRetype').errors
                    ?.minlength
                "
                class="app-error"
              >
                Nhập lại mật khẩu email không được bé hơn 6 ký tự
              </span>
              <span
                *ngIf="
                  getFormControlInFormGroup('fromEmailPassRetype').errors
                    ?.maxlength
                "
                class="app-error"
              >
                Nhập lại mật khẩu email không được lớn hơn 255 ký tự
              </span>
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="flex flex-row justify-center items-center">
        <button
          nz-button
          type="button"
          class="btn-check-send-email"
          (click)="checkSendMail()"
        >
          Kiểm tra gửi email
        </button>
      </div>
    </div>
    <hr class="black-horizontal-line" />
    <!-- footer -->
    <div class="flex flex-row justify-end gap-2">
      <button
        nz-button
        nzType="primary"
        class="btn-plus"
        (click)="updateUserInfo()"
      >
        <img src="./assets/images/icons/edit.png" class="logo-img mr-1" />
        Cập nhật
      </button>
      <button
        nz-button
        nzType="primary"
        class="btn-back"
        (click)="cancelChangeUserInfoComponent()"
      >
        <img src="./assets/images/icons/back.png" class="logo-img mr-1" />
        Quay lại
      </button>
    </div>

    <nz-modal
      [(nzVisible)]="isShowAcceptModal"
      [nzFooter]="null"
      nzMaskClosable="false"
      (nzOnCancel)="closeAcceptModal()"
    >
      <ng-container *nzModalContent>
        <div class="flex flex-col justify-center items-center gap-4 px-10 mt-5">
          <div class="flex flex-row items-center gap-1">
            <img
              src="./assets/images/icons/refuse_big.svg"
              class="logo-img mr-1"
            />
          </div>
          <div class="flex items-center justify-center text-center">
            Nhập lại mật khẩu mới chưa chính xác. Vui lòng kiểm tra lại!
          </div>
          <div class="flex flex-row gap-3 mb-5">
            <button nz-button class="btn-cancel" (click)="closeAcceptModal()">
              Đóng
            </button>
          </div>
        </div>
      </ng-container>
    </nz-modal>
  </form>
</div>
