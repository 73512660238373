import { Injectable, OnDestroy, Pipe } from '@angular/core';
import {BehaviorSubject, finalize, Observable, of, Subscription, throwError} from 'rxjs';
import { AgencyHttpService } from './agency-http.service';
import {Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgencyService implements OnDestroy {
  private unsubscribe: Subscription[] = [];

  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private agencyHttpService: AgencyHttpService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);

    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  getAllAgencies(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.agencyHttpService.getAllAgencies().pipe(
      map((res: any) => {
        return res.map((item: any) => {
          return {
            id: item.id,
            code: item.id,
            name: item.agencyName
          };
        });
      }),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getFullAgencies(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.agencyHttpService.getAllAgencies().pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getAgency(id: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.agencyHttpService.getAgency(id).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  addAgency(payload: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.agencyHttpService.addAgency(payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  updateAgency(payload: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.agencyHttpService.updateAgency(payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  deleteAgency(id: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.agencyHttpService.deleteAgency(id).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
