import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/services';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'cons-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  isCollapsed = true;
  user$: Observable<any>;
  isShowLogoutConfirmLModal = false;
  isShowChangeUserInfoModal = false;

  tab: string = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private cdref: ChangeDetectorRef
  ) {
    this.user$ = this.authService.currentUser$;
  }

  ngOnInit(): void {
    if (this.router.url === '/') {
      this.router.navigate(['/cert']);
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  onLogout(): void {
    this.authService.logout();
  }

  closeModalConfirmLogout(): void {
    this.isShowLogoutConfirmLModal = false;
  }

  showModalConfirmLogout(): void {
    this.isShowLogoutConfirmLModal = true;
  }

  closeModalChangeUserInfo(): void {
    this.isShowChangeUserInfoModal = false;
  }

  showModalChangeUserInfo(): void {
    this.isShowChangeUserInfoModal = true;
  }

  hasPermission(permission: string): boolean {
    return this.authService.hasPermission(permission);
  }

  breadcrumb() {
    if (this.router.url && this.router.url.includes('category-type')) {
      this.tab = 'Danh mục';
      return 'Loại danh mục';
    } else if (this.router.url && this.router.url.includes('user-management')) {
      this.tab = 'Cơ cấu tổ chức';
      return 'Quản trị người sử dụng';
    } else if (this.router.url && this.router.url.includes('register-cts')) {
      this.tab = 'Dịch vụ chữ ký số';
      return 'Đăng ký cấp Chứng thư số';
    } else if (this.router.url && this.router.url.includes('waiting-cts')) {
      this.tab = 'Dịch vụ chữ ký số';
      return 'Chờ cấp Chứng thư số';
    } else if (this.router.url && this.router.url.includes('granted-cts')) {
      this.tab = 'Dịch vụ chữ ký số';
      return 'Đã cấp Chứng thư số';
    } else if (
      this.router.url &&
      this.router.url.includes('cancel-registration-cts')
    ) {
      this.tab = 'Dịch vụ chữ ký số';
      return 'Hủy đăng ký';
    } else if (
      this.router.url &&
      this.router.url.includes('signature-statistics')
    ) {
      this.tab = 'Dịch vụ chữ ký số';
      return 'Thống kê lượt ký';
    } else if (this.router.url && this.router.url.includes('crud-agency')) {
      this.tab = 'Đại lý';
      return 'Danh sách đại lý';
    } else if (this.router.url && this.router.url.includes('crud-package')) {
      this.tab = 'Gói cước';
      return 'Quản lý gói cước';
    } else if (this.router.url && this.router.url.includes('unchecked-cts')) {
      this.tab = 'Dịch vụ chữ ký số';
      return 'Chưa kiểm tra';
    } else if (this.router.url && this.router.url.includes('checked-cts')) {
      this.tab = 'Dịch vụ chữ ký số';
      return 'Đã kiểm tra';
    } else if (this.router.url && this.router.url.includes('invalid-cts')) {
      this.tab = 'Dịch vụ chữ ký số';
      return 'Hồ sơ - Không hợp lệ';
    } else if (this.router.url && this.router.url.includes('authorization')) {
      this.tab = 'Cơ cấu tổ chức';
      return 'Phân quyền';
    }
    this.tab = 'Dịch vụ chữ ký số';
    return 'Dịch vụ chữ ký số';
  }
}
