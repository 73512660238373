import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { first, Observable, Subscription } from 'rxjs';
import { UserModel } from 'src/app/auth/models/user.model';
import { AuthService } from 'src/app/auth/services';
@Component({
  selector: 'app-login-sso-callback',
  template: '',
  styles: [''],
})
export class LoginSSOCallback implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];
  returnUrl: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private message: NzMessageService
  ) {}

  ngOnInit() {
    this.returnUrl =
      this.route.snapshot.queryParams['returnUrl'.toString()] ||
      '/cert/register-cts';
    this.router.events.subscribe((params: any) => {
      this.route.fragment.subscribe((fragment) => {
        if (fragment) {
          const params = new URLSearchParams(fragment);
          const token = params.get('id_token');
          // console.log(token);
          if (token) {
            // login crm
            const loginSSOSubscr = this.authService
              .loginSSO(token)
              .pipe(first())
              .subscribe(
                (user: UserModel | undefined) => {
                  if (user) {
                    this.router.navigate([this.returnUrl]);
                  } else {
                    this.router.navigate(['/auth/login'], {
                      queryParams: {},
                    });
                  }
                },
                (err: any) => {
                  if (
                    err.error.status_code === '400' &&
                    (err.error.message === 'Bạn đã bị khóa 10 phút' ||
                      err.error.message === 'User are locked 10 minutes')
                  ) {
                    this.message.error(
                      'Nhập sai quá số lần quy định, tạm khóa tài khoản 10 phút. Vui lòng đăng nhập lại sau ^.^'
                    );
                  } else if (err.status === 500 || err.status === 504) {
                    this.message.error('Lỗi hệ thống, vui lòng thử lại sau');
                  } else if (err.error.status_code === '400') {
                    if (
                      err.error.message === 'Người dùng không tồn tại' ||
                      err.error.message === 'User not found'
                    ) {
                      let text = '';
                      if (err.error.errors.username) {
                        text = 'username: ' + err.error.errors.username;
                      }
                      if (err.error.errors.email) {
                        text = 'email: ' + err.error.errors.email;
                      }
                      this.message.error(
                        'Không tồn tại tài khoản tương ứng với ' +
                          text +
                          ' trên hệ thống CRM'
                      );
                    } else this.message.error(err.error.message);
                  } else
                    this.message.error(
                      'Lỗi hệ thống, vui lòng liên hệ Admin để nhận hỗ trợ: ' +
                        err.error.message
                    );
                  this.authService.logout();
                }
              );
            this.unsubscribe.push(loginSSOSubscr);
          } else this.authService.logout();
          this.route.fragment = new Observable();
        } else this.authService.logout();
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
