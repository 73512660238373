import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  finalize,
  Observable,
  of,
  Subscription,
  switchMap,
  throwError,
} from 'rxjs';
import { AppConstants } from '../../app-constants';
import { UserModel } from '../models/user.model';
import { AuthHttpService } from './auth-http.service';
import { Router } from '@angular/router';
import { AuthModel } from '../models/auth.model';
import { catchError, map } from 'rxjs/operators';
import { RegistrationModel } from '../models/registration.model';
import { environment } from 'src/environments/environment';

export type UserType = UserModel | undefined;
export type ListPage =
  | 'white-page'
  | 'cert'
  | 'category'
  | 'organizational-structure'
  | 'agency'
  | 'package';
export type ListTab =
  | 'white-tab'
  | 'register-cert'
  | 'waiting-cert'
  | 'issued-cert'
  | 'cancel-register-cert'
  | 'signing-statistic'
  | 'cert-invalid'
  | 'unchecked-cert'
  | 'checked-cert'
  | 'category-type'
  | 'sys-user'
  | 'permission'
  | 'list-agency'
  | 'package-management';
export interface Tab {
  codeTab: ListTab;
  listPermission: string[];
  listPermissionName: string[];
}
export interface Page {
  codePage: ListPage;
  namePage: string;
  listTab: Tab[];
}

const API_IOFFICE_URL = `${environment.apiIOffice}`;
const API_FE_URL = `${environment.apiPortFe}`;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private unsubscribe: Subscription[] = [];
  private authLocalStorageToken = AppConstants.APP_AUTH;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHttpService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  public listTabShow: Page[] = [
    // 3 page đầu fix cứng (// combine white-page with cert --authorization-info.component.ts)
    // KO đổi thứ tự, chỉ đổi listPermission nếu có trường hợp toàn hệ thống và
    // show khi có chức năng mà ko dính dáng đến page nào khác
    {
      codePage: 'white-page',
      listTab: [
        {
          codeTab: 'white-tab',
          listPermission: ['ROLE_LOGIN', 'ROLE_LOGIN_MDS'],
          listPermissionName: ['Đăng nhập hệ thống', 'Đăng nhập api MDS'],
        },
      ],
      namePage: 'CRM',
    },
    // page này ko show mà khi có chức năng trong page này thì nó sẽ tự động hiển thị lên ở page Quản lý CTS khi có quyền
    // cho vào để có thể chọn chức năng trong phần phân quyền
    {
      codePage: 'white-page',
      listTab: [
        {
          codeTab: 'white-tab',
          listPermission: [
            'ROLE_GET_DIARY_CERT',
            'ROLE_GET_APPLICATION_FOR_CERT',
            'ROLE_EXPORT_FILE',
          ],
          listPermissionName: [
            'Xem lịch sử',
            'Lấy đơn đề nghị cấp CTS',
            'Xuất file DS',
          ],
        },
      ],
      namePage: 'Page ko hiển thị',
    },
    // các page còn lại sẽ show lên như thường, muốn init gì thì update từ sau page này
    {
      codePage: 'cert',
      listTab: [
        {
          codeTab: 'register-cert',
          listPermission: [
            'ROLE_REGISTER_CERT',
            'ROLE_UPDATE_CERT',
            'ROLE_CHUYEN_CAP_CTS',
            'ROLE_REGISTERED_CERT',
            'ROLE_IMPORT_FORM_REGISTER_FILE',
            'ROLE_HUY_DANG_KY',
          ],
          listPermissionName: [
            'Thêm mới',
            'Cập nhật',
            'Chuyển cấp CTS',
            'Tạo và chuyển cấp CTS',
            'Import dữ liệu',
            'Hủy đăng ký',
          ],
        },
        {
          codeTab: 'waiting-cert',
          listPermission: [
            'ROLE_UPDATE_CERT',
            'ROLE_TU_CHOI_CAP_CTS',
            'ROLE_ADD_CERT_CHECK_INFO_ADMIN',
            'ROLE_DUYET_CAP_CTS',
            'ROLE_CHUYEN_CAP_CTS',
            'ROLE_REGISTERED_CERT',
          ],
          listPermissionName: [
            'Cập nhật hồ sơ cấp CTS',
            'Từ chối cấp CTS',
            'Kiểm tra HS CTS (Admin)',
            'Duyệt cấp CTS',
            'Chuyển cấp CTS',
            'Tạo và chuyển cấp CTS',
          ],
        },
        {
          codeTab: 'issued-cert',
          listPermission: [
            'ROLE_UPDATE_CERT',
            'ROLE_DUYET_CAP_CTS',
            'ROLE_REVOKE_CERT',
            'ROLE_TEMP_LOCK_CERT',
            'ROLE_UNLOCK_CERT',
            'ROLE_SYNC_CERT_IDP',
            'ROLE_RESET_DEVICE_USER',
            'ROLE_REISSUE_CHANGE_CERT_INFO',
            'ROLE_ADD_CERT_CHECK_INFO_ADMIN',
            'ROLE_BUY_NEW_CERT',
            'ROLE_EXTEND_CERT',
          ],
          listPermissionName: [
            'Cập nhật hồ sơ cấp CTS',
            'Duyệt cấp CTS',
            'Thu hồi',
            'Tạm khóa',
            'Mở khóa',
            'Đồng bộ từ IDP',
            'Reset thiết bị gốc',
            'Cấp lại (Đổi thông tin)',
            'Kiểm tra HS CTS (Admin)',
            'Mua thêm',
            'Gia hạn',
          ],
        },
        {
          codeTab: 'cancel-register-cert',
          listPermission: [
            'ROLE_UPDATE_CERT',
            'ROLE_HUY_DANG_KY',
            'ROLE_KHOI_PHUC_HO_SO_CAP_CTS',
            'ROLE_DELETE_CERT',
          ],
          listPermissionName: [
            'Cập nhật hồ sơ cấp CTS',
            'Hủy đăng ký',
            'Khôi phục CTS',
            'Xóa hồ sơ',
          ],
        },
        {
          codeTab: 'signing-statistic',
          listPermission: ['ROLE_GET_COUNT_SIGNING'],
          listPermissionName: ['Thống kê lượt ký'],
        },
        {
          codeTab: 'cert-invalid',
          listPermission: [
            'ROLE_REVOKE_CERT',
            'ROLE_TEMP_LOCK_CERT',
            'ROLE_UNLOCK_CERT',
            'ROLE_SYNC_CERT_IDP',
            'ROLE_RESET_DEVICE_USER',
            'ROLE_REISSUE_CHANGE_CERT_INFO',
            'ROLE_ADD_CERT_CHECK_INFO_ADMIN',
            'ROLE_BUY_NEW_CERT',
            'ROLE_EXTEND_CERT',
          ],
          listPermissionName: [
            'Thu hồi',
            'Tạm khóa',
            'Mở khóa',
            'Đồng bộ từ IDP',
            'Reset thiết bị gốc',
            'Cấp lại (Đổi thông tin)',
            'Kiểm tra HS CTS (Admin)',
            'Mua thêm',
            'Gia hạn',
          ],
        },
        {
          codeTab: 'unchecked-cert',
          listPermission: ['ROLE_ADD_CERT_CHECK_INFO_AUDIT'],
          listPermissionName: ['Kiểm tra HS CTS (Audit)'],
        },
        {
          codeTab: 'checked-cert',
          listPermission: ['ROLE_ADD_CERT_CHECK_INFO_AUDIT'],
          listPermissionName: ['Kiểm tra HS CTS (Audit)'],
        },
      ],
      namePage: 'Quản lý chứng thư số',
    },
    {
      codePage: 'category',
      listTab: [
        {
          codeTab: 'category-type',
          listPermission: [
            'ROLE_ADD_CATEGORY',
            'ROLE_UPDATE_CATEGORY',
            'ROLE_DELETE_CATEGORY',
          ],
          listPermissionName: ['Thêm mới', 'Cập nhật', 'Xóa'],
        },
      ],
      namePage: 'Danh mục',
    },
    {
      codePage: 'organizational-structure',
      listTab: [
        {
          codeTab: 'sys-user',
          listPermission: [
            'ROLE_ADD_USER',
            'ROLE_UPDATE_USER',
            'ROLE_DELETE_USER',
          ],
          listPermissionName: [
            'Thêm tài khoản',
            'Cập nhật tài khoản',
            'Xóa tài khoản',
          ],
        },
        {
          codeTab: 'permission',
          listPermission: [
            'ROLE_ADD_SYS_ROLE',
            'ROLE_UPDATE_SYS_ROLE',
            'ROLE_DELETE_SYS_ROLE',
          ],
          listPermissionName: [
            'Thêm nhóm quyền',
            'Cập nhật nhóm quyền',
            'Xóa nhóm quyền',
          ],
        },
      ],
      namePage: 'Cơ cấu tổ chức',
    },
    {
      codePage: 'agency',
      listTab: [
        {
          codeTab: 'list-agency',
          listPermission: [
            'ROLE_ADD_AGENCY',
            'ROLE_UPDATE_AGENCY',
            'ROLE_DELETE_AGENCY',
          ],
          listPermissionName: ['Thêm mới', 'Cập nhật', 'Xóa'],
        },
      ],
      namePage: 'Đại lý',
    },
    {
      codePage: 'package',
      listTab: [
        {
          codeTab: 'package-management',
          listPermission: [
            'ROLE_ADD_PACKAGE',
            'ROLE_UPDATE_PACKAGE',
            'ROLE_DELETE_PACKAGE',
          ],
          listPermissionName: ['Thêm mới', 'Cập nhật', 'Xóa'],
        },
      ],
      namePage: 'Gói cước',
    },
  ];

  setRole(user: any): boolean {
    let auth = this.getAuthFromLocalStorage();
    if (auth && auth.accessToken && user.listPermission) {
      let listPermission: string[] = user.listPermission;
      for (let i = 0; i < user.listPermission.length; i++) {
        for (let j = 0; j < this.listTabShow.length; j++) {
          for (let h = 0; h < this.listTabShow[j].listTab.length; h++) {
            if (
              this.listTabShow[j].listTab[h].listPermission.indexOf(
                user.listPermission[i]
              ) !== -1
            ) {
              if (listPermission.indexOf(this.listTabShow[j].codePage) === -1)
                listPermission.push(this.listTabShow[j].codePage);
              if (
                listPermission.indexOf(
                  this.listTabShow[j].listTab[h].codeTab
                ) === -1
              )
                listPermission.push(this.listTabShow[j].listTab[h].codeTab);
            }
          }
        }
      }
      auth.listPermission = listPermission;
      return this.setAuthFromLocalStorage(auth);
    }
    return false;
  }

  private listPermission: string[] | undefined = [];

  hasPermission(permission: string): boolean {
    if (
      (this.listPermission && this.listPermission.length === 0) ||
      !this.listPermission
    ) {
      let auth = this.getAuthFromLocalStorage();
      this.listPermission = auth?.listPermission;
    }
    if (this.listPermission && this.listPermission.length !== 0) {
      return this.listPermission.includes(permission);
    } else {
      return false;
    }
  }

  register(payload: RegistrationModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.signup(payload).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return of(null);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  login(username: string, password: string): Observable<UserType> {
    this.currentUserSubject.next(undefined);
    localStorage.removeItem(this.authLocalStorageToken);
    this.listPermission = undefined;
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(username, password).pipe(
      map((res: any) => {
        return this.setAuthFromLocalStorage(res);
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  loginSSO(token: string): Observable<any> {
    this.currentUserSubject.next(undefined);
    localStorage.removeItem(this.authLocalStorageToken);
    this.listPermission = undefined;
    this.isLoadingSubject.next(true);
    return this.authHttpService.loginSSO(token).pipe(
      map((res: any) => {
        return this.setAuthFromLocalStorage(res);
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    const auth = this.getAuthFromLocalStorage();
    this.currentUserSubject.next(undefined);
    localStorage.removeItem(this.authLocalStorageToken);
    this.listPermission = undefined;
    if (auth?.tokenSSO) {
      const client_id = encodeURIComponent('CRM_LOCAL');
      const redirect_uri = encodeURIComponent(`${API_FE_URL}/auth/logout-sso`);
      const ssoUrl = `${API_IOFFICE_URL}/logout`;
      window.location.href = `${ssoUrl}?client_id=${client_id}&redirect_uri=${redirect_uri}`;
    } else
      this.router.navigate(['/auth/login'], {
        queryParams: {},
      });
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.accessToken).pipe(
      map((res: any) => {
        const user = new UserModel();
        user.setUser(res);

        if (user && this.setRole(res)) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(username: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(username)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  confirmForgotPassword(token: string, newPassword: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .confirmForgotPassword(token, newPassword)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    if (auth && auth.accessToken) {
      const authLocal = this.getAuthFromLocalStorage();
      if (authLocal && authLocal.tokenSSO && !auth.tokenSSO)
        auth.tokenSSO = authLocal.tokenSSO;
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }
      return JSON.parse(lsValue);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
