import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ErrorResponse } from '../models/error-response.model';

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleHttpError(error);
        return throwError(error);
      })
    );
  }

  handleHttpError(error: HttpErrorResponse) {
    switch ((error as HttpErrorResponse).status) {
      // connection error
      case 0:
        this.handleConnectionError(error);
        break;
      // validation error
      case 400:
        this.setHttpResponseError(error);
        break;
      // authentication error
      case 401:
        //this.handle401Error(error);
        break;
      // role error
      case 403:
        break;
      // not found error
      case 404:
        break;
      case 500:
        this.handleConnectionError(error);
        break;
      case 504:
        this.handleConnectionError(error);
        break;
      // default
      default:
        this.setHttpResponseError(error);
    }
  }

  setHttpResponseError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorResponse) {
      // Client error
      errorMessage = `${error.error.message}`;
    } else {
      // Api error response
      let errorResponse: ErrorResponse = new ErrorResponse();
      errorResponse.message = error.error.message;
      errorResponse.statusCode = error.error.status_code;
      errorResponse.errors = error.error.errors;
      return throwError(errorResponse);
    }
    return throwError(errorMessage);
  }

  handleConnectionError(error: HttpErrorResponse) {
    let errorResponse: ErrorResponse = new ErrorResponse();
    errorResponse.message = 'Server not response';
    errorResponse.statusCode = '500';
    return throwError(errorResponse);
  }

  /*intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(0),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // Client error
            errorMessage = `${error.error.message}`;
          } else {
            // Api error response
            let errorResponse: ErrorResponse = new ErrorResponse();
            errorResponse.message = error.error.message;
            errorResponse.statusCode = error.error.status_code;
            errorResponse.errors = error.error.errors;
            return throwError(errorResponse);
          }
          return throwError(errorMessage);
        })
      )
  }*/
}
