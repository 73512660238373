import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  finalize,
  Observable,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { PackageHttpService } from './package-http.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PackageService implements OnDestroy {
  private unsubscribe: Subscription[] = [];

  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private packageHttpService: PackageHttpService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);

    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  getPackageByCertType(certType: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.packageHttpService.getPackageByCertType(certType).pipe(
      map((res: any) => {
        return res.map((item: any) => {
          return {
            id: item.id,
            code: item.packageCode,
            price: item.price,
            certType: item.certType,
            name: `${item.packageCode} - ${item.description}`,
          };
        });
      }),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getFullPackage(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.packageHttpService.getAllPackage().pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getPackage(package_code: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.packageHttpService.getPackage(package_code).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  searchPackage(certType: string, status: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.packageHttpService.searchPackage(certType, status).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  deletePackage(package_code: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.packageHttpService.deletePackage(package_code).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  addPackage(payload: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.packageHttpService.addPackage(payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  updatePackage(payload: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.packageHttpService.updatePackage(payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
