import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  username_required: () => `Chưa nhập tên đăng nhập!`,
  username_maxlength: (errors: any) => `Tên đăng nhập không được dài quá ${errors.requiredLength} ký tự!`,
  password_required: () => `Chưa nhập mật khẩu!`,
  password_minlength: (errors: any) => `Mật khẩu tối thiểu ${errors.requiredLength} ký tự!`,
  password_maxlength: (errors: any) => `Mật khẩu không được dài quá ${errors.requiredLength} ký tự!`,
  agencyCode_required: () => `Đại lý không được để trống`,
  createdBy_required: () => `Người tạo không được để trống`,
  contactPhone_required: () => `ĐT liên hệ không được để trống`,
  contactEmail_required: () => `Email liên hệ không được để trống`,
  emailContact_email: () => `Email không hợp lệ`,
  certType_required: () => `Loại CTS không được để trống`,
  registrationSource_required: () => `Nguồn đăng ký không được để trống`,
  typeRequest_required: () => `Loại yêu cầu không được để trống`,
  numberInfo_required: () => `Số không được để trống`,
  statusRegistration_required: () => `Trạng thái đăng ký không được để trống`,
  name_required: () => `Tên CN đề nghị không được để trống`,
  verifyMethod_required: () => `Phương thức XM không được để trống`,
  requestType_required: () => `Loại yêu cầu không được để trống`,
  certMethod_required: () => `Phương thức CTS không được để trống`,
  paperType_required: () => `Loại hồ sơ cấp CTS không được để trống`,
  identifierCode_required: () => `Số không được để trống`,
  personalName_required: () => `Tên CN/TC đề nghị không được để trống`,
  phoneNumber_required: () => `Mobile xác thực không được để trống`,
  email_required: () => `Email xác thực không được để trống`,
  packData_required: () => `Gói cước không được để trống`,
  appCode_required: () => `App xác thực không được để trống`,
  organizationPaperType_required: () => `Thuộc tổ chức không được để trống`,
  onizationIdentifierCode_required: () => `Số không được để trống`,
  organizationName_required: () => `Tên tổ chức không được để trống`,
  packageCode_required: () => `Gói cước không được để trống`,
  province_required: () => `Tỉnh/TP không được để trống`,
  district_required: () => `Quận/Huyện không được để trống`,
  status_required: () => `Trạng thái đăng ký không được để trống`,
  representativeName_required: () => `Người đại diện không được để trống`,
  representativePosition_required: () => `Chức vụ không được để trống`,
  passwordOld_required: () => `Mật khẩu cũ không được để trống`,
  passwordNew_required: () => `Mật khẩu mới không được để trống`,
  passwordNewRetype_required: () => `Nhập lại mật khẩu mới không được để trống`,
  emailServer_required: () => `Server của email không được để trống`,
  urlEmailServer_required: () => `Địa chỉ máy chủ email server không được để trống`,
  connectionPort_required: () => `Cổng kết nối không được để trống`,
  fromEmailUser_required: () => `Địa chỉ email gửi đi không được để trống`,
  fromEmailPass_required: () => `Mật khẩu email không được để trống`,
  fromEmailPassRetype_required: () => `Nhập lại mật khẩu email không được để trống`,
  fromEmailUser_email: () => `Email không hợp lệ`,

  min: (errors: any) => `Expect minimum ${errors.min} but got ${errors.actual}`,
  max: (errors: any) =>  `Expect maximum ${errors.max} but got ${errors.actual}`,
  required: () => `This field is required`,
  minlength: (errors: any) => `Expect minimum ${errors.requiredLength} but got ${errors.actualLength}`,
  maxlength: (errors: any) => `Expect maximum ${errors.requiredLength} but got ${errors.actualLength}`,
  email: () => `Require email`,
  unique: () => `Field is unique`,
  mustMatch: (errors: any) => `${errors.matchingControl.label} not match for ${errors.control.label}`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});
