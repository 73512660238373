import { CategoryService } from './category.service';
import { CategoryHttpService } from './category-http.service';
import { LocationHttpService } from './location-http.service';
import { LocationService } from './location.service';
import { PackageHttpService } from './package-http.service';
import { PackageService } from './package.service';
import { AgencyHttpService } from './agency-http.service';
import { AgencyService } from './agency.service';
import { UserHttpService } from './user-http.service';
import { UserService } from './user.service';

export const services: any[] = [
  CategoryService,
  CategoryHttpService,
  LocationHttpService,
  LocationService,
  PackageHttpService,
  PackageService,
  AgencyHttpService,
  AgencyService,
  UserHttpService,
  UserService
];

export * from './category.service';
export * from './category-http.service';
export * from './location-http.service';
export * from './location.service';
export * from './package-http.service';
export * from './package.service';
export * from './agency-http.service';
export * from './agency.service';
export * from './user-http.service';
export * from './user.service';
