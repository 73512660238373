import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  finalize,
  Observable,
  of,
  Subscription,
  switchMap,
  throwError,
} from 'rxjs';
import { AppConstants } from '../../app-constants';
import { CategoryHttpService } from './category-http.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { ChangeSysUserInfoHttpService } from './change-sys-user-info-http.service';

@Injectable({
  providedIn: 'root',
})
export class ChangeSysUserInfoService implements OnDestroy {
  private unsubscribe: Subscription[] = [];

  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private changeSysUserInfoHttpService: ChangeSysUserInfoHttpService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);

    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.changeSysUserInfoHttpService
      .changePassword(oldPassword, newPassword)
      .pipe(
        map((res) => {
          this.isLoadingSubject.next(false);
          // console.log(res);
          return res;
        }),
        catchError((err) => {
          // console.log(err);
          return throwError(err);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
