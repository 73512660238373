<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo flex items-center justify-between">
      <a [routerLink]="['/cert/register-cts']">
        <img src="./assets/images/logo/logo.png" alt="logo" />
      </a>
      <span
        *ngIf="!isCollapsed"
        (click)="isCollapsed = !isCollapsed"
        class="pr-4 cursor-pointer"
      >
        <i class="fas fa-bars fa-1x text-white hover:text-[#1890ff]"></i>
      </span>
    </div>
    <ul
      nz-menu
      nzTheme="dark"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li
        nz-submenu
        nzOpen
        nzTitle="Dịch vụ chữ ký số"
        nzIcon="home"
        *ngIf="hasPermission('cert')"
      >
        <ul>
          <li nz-submenu nzOpen nzTitle="Quản lý Chứng thư số">
            <ul>
              <li
                nz-menu-item
                nzMatchRouter
                nzSelected
                *ngIf="hasPermission('register-cert')"
              >
                <a [routerLink]="['/cert/register-cts']"
                  >Đăng ký cấp Chứng thư số</a
                >
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                *ngIf="hasPermission('waiting-cert')"
              >
                <a [routerLink]="['/cert/waiting-cts']">Chờ cấp Chứng thư số</a>
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                *ngIf="hasPermission('issued-cert')"
              >
                <a [routerLink]="['/cert/granted-cts']">Đã cấp Chứng thư số</a>
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                *ngIf="hasPermission('cancel-register-cert')"
              >
                <a [routerLink]="['/cert/cancel-registration-cts']"
                  >Hủy đăng ký</a
                >
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                *ngIf="hasPermission('signing-statistic')"
              >
                <a [routerLink]="['/cert/signature-statistics']"
                  >Thống kê lượt ký</a
                >
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                *ngIf="hasPermission('unchecked-cert')"
              >
                <a [routerLink]="['/cert/unchecked-cts']">Chưa kiểm tra</a>
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                *ngIf="hasPermission('checked-cert')"
              >
                <a [routerLink]="['/cert/checked-cts']">Đã kiểm tra</a>
              </li>
              <li
                nz-menu-item
                nzMatchRouter
                *ngIf="hasPermission('cert-invalid')"
              >
                <a [routerLink]="['/cert/invalid-cts']">Hồ sơ - Không hợp lệ</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li
        nz-submenu
        nzTitle="Danh mục"
        nzIcon="unordered-list"
        *ngIf="hasPermission('category')"
      >
        <ul>
          <li
            nz-menu-item
            nzMatchRouter
            nzSelected
            *ngIf="hasPermission('category-type')"
          >
            <a [routerLink]="['/category/category-type']">Loại danh mục</a>
          </li>
        </ul>
      </li>
      <li
        nz-submenu
        nzTitle="Cơ cấu tổ chức"
        nzIcon="user"
        *ngIf="hasPermission('organizational-structure')"
      >
        <ul>
          <li
            nz-menu-item
            nzMatchRouter
            nzSelected
            *ngIf="hasPermission('sys-user')"
          >
            <a [routerLink]="['/organizational-structure/user-management']"
              >Quản trị người sử dụng</a
            >
          </li>
        </ul>
        <ul>
          <li
            nz-menu-item
            nzMatchRouter
            nzSelected
            *ngIf="hasPermission('permission')"
          >
            <a [routerLink]="['/organizational-structure/authorization']"
              >Phân quyền</a
            >
          </li>
        </ul>
      </li>
      <li
        nz-submenu
        nzTitle="Đại lý"
        nzIcon="idcard"
        *ngIf="hasPermission('agency')"
      >
        <ul>
          <li
            nz-menu-item
            nzMatchRouter
            nzSelected
            *ngIf="hasPermission('list-agency')"
          >
            <a [routerLink]="['/agency/crud-agency']">Danh sách đại lý</a>
          </li>
        </ul>
      </li>
      <li
        nz-submenu
        nzTitle="Gói cước"
        nzIcon="shopping-cart"
        *ngIf="hasPermission('package')"
      >
        <ul>
          <li
            nz-menu-item
            nzMatchRouter
            nzSelected
            *ngIf="hasPermission('package-management')"
          >
            <a [routerLink]="['/package/crud-package']">Quản lý gói cước</a>
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <div class="w-100 flex items-center">
          <span
            *ngIf="isCollapsed"
            class="header-trigger"
            (click)="isCollapsed = !isCollapsed"
          >
            <i class="fas fa-bars fa-1x text-primary hover:text-[#1890ff]"></i>
          </span>
          <span class="pl-[20px] text-primary font-semibold"
            >CÔNG TY CỔ PHẦN INTRUST</span
          >
        </div>
        <div class="pr-10">
          <div class="flex flex-row items-center justify-between gap-5">
            <div class="flex flex-row justify-between">
              <a
                nz-dropdown
                [nzDropdownMenu]="menu"
                *ngIf="user$ | async as userInfo"
                class="flex info gap-1"
                style="margin-right: 10px"
              >
                <img
                  src="./assets/images/icons/user_icon.svg"
                  class="logo-img-user"
                />
                <span>{{ userInfo?.name || "Tên" }}</span>
              </a>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <div class="users-info">
                  <div class="info-user">
                    <img src="./assets/images/icons/user_icon.svg" />
                  </div>
                  <div class="center" style="margin-bottom: 15px">
                    <span *ngIf="user$ | async as userInfo">{{
                      userInfo?.name || "Tên"
                    }}</span>
                  </div>
                  <div class="center flex-item">
                    <button
                      (click)="showModalChangeUserInfo()"
                      type="button"
                      style="width: 230px; margin: 0px"
                      class="text-white bg-teal-500 hover:bg-teal-600 focus:ring-4 focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-teal-600 dark:hover:bg-teal-700 focus:outline-none dark:focus:ring-teal-800"
                    >
                      Thay đổi thông tin
                    </button>
                  </div>
                </div>
              </nz-dropdown-menu>
            </div>
            <div
              class="flex flex-row items-center btn-logout cursor-pointer"
              (click)="showModalConfirmLogout()"
            >
              <img
                src="./assets/images/icons/logout.png"
                class="logo-img ml-4"
              />
            </div>
          </div>
        </div>
      </div>
    </nz-header>
    <!-- <nz-breadcrumb class="px-6 py-3">
      <nz-breadcrumb-item>
        <span
          [routerLink]="['/cert/register-cts']"
          nz-icon
          nzType="home"
        ></span>
      </nz-breadcrumb-item>
      <nz-breadcrumb-item>{{ breadcrumb() }}</nz-breadcrumb-item> -->
    <nz-breadcrumb class="px-6 py-3 flex flex-row items-center gap-2">
      <ng-container *ngIf="tab === 'Dịch vụ chữ ký số'">
        <span
          class="item-center"
          [routerLink]="['/cert/register-cts']"
          nz-icon
          nzType="home"
        ></span>
        <span>/</span>
        <span>Quản lý Chứng thư số</span>
      </ng-container>
      <ng-container *ngIf="tab === 'Danh mục'">
        <span
          [routerLink]="['/category/category-type']"
          nz-icon
          nzType="unordered-list"
        ></span>
      </ng-container>
      <ng-container *ngIf="tab === 'Cơ cấu tổ chức'">
        <span
          [routerLink]="['/organizational-structure/user-management']"
          nz-icon
          nzType="user"
        ></span>
      </ng-container>
      <ng-container *ngIf="tab === 'Đại lý'">
        <span
          [routerLink]="['/agency/crud-agency']"
          nz-icon
          nzType="idcard"
        ></span>
      </ng-container>
      <ng-container *ngIf="tab === 'Gói cước'">
        <span
          [routerLink]="['/package/crud-package']"
          nz-icon
          nzType="shopping-cart"
        ></span>
      </ng-container>
      <span>/</span>
      <span style="color: black">{{ breadcrumb() }}</span>
    </nz-breadcrumb>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
<footer class="app-footer shadow-lg shadow-slate-900/20 shadow-inner">
  <div class="flex flex-row items-center gap-10">
    <div class="flex flex-row items-center gap-1">
      <img src="./assets/images/icons/ultraview.png" class="logo-img" />
      <span class="font-medium text-blue-500">UltraViewer</span>
    </div>
    <div class="flex flex-row items-center gap-1">
      <img src="./assets/images/icons/phone_call.png" class="logo-img" />
      <span class="font-medium text-blue-500">Hotline: 0243 997 8728</span>
    </div>
    <div class="flex flex-row items-center gap-1">
      <img src="./assets/images/icons/email.png" class="logo-img" />
      <span class="font-medium text-blue-500">Email: vp@intrust.com.vn</span>
    </div>
  </div>
  <div class="flex flex-row items-center gap-5">
    <div class="flex flex-row items-center gap-1">
      <img src="./assets/images/icons/2024.png" class="logo-img" />
    </div>
    <div class="flex flex-row items-center gap-1">
      <img src="./assets/images/logo/logo.png" class="logo-img-footer" />
      <span class="font-medium text-blue-500">Công ty cổ phần Intrust</span>
    </div>
  </div>
</footer>
<nz-modal
  [(nzVisible)]="isShowLogoutConfirmLModal"
  nzTitle="Đăng xuất"
  [nzFooter]="null"
  nzMaskClosable="false"
  nzClosable="false"
  (nzOnCancel)="closeModalConfirmLogout()"
>
  <ng-container *nzModalContent>
    <div class="flex flex-col justify-between items-center gap-5">
      <div class="flex flex-row items-center gap-1">
        <img src="./assets/images/icons/refuse_big.svg" class="logo-img mr-1" />
      </div>
      <span class="font-medium">Bạn có chắc chắn muốn đăng xuất không?</span>
      <div class="flex flex-row gap-3">
        <button
          nz-button
          nzType="primary"
          class="btn-confirm"
          (click)="onLogout()"
        >
          Yes
        </button>
        <button
          nz-button
          class="btn-cancel"
          (click)="closeModalConfirmLogout()"
        >
          No
        </button>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- change user info -->
<nz-modal
  [(nzVisible)]="isShowChangeUserInfoModal"
  [nzStyle]="{ top: '20px' }"
  nzTitle="THAY ĐỔI THÔNG TIN NGƯỜI SỬ DỤNG"
  [nzFooter]="null"
  nzMaskClosable="false"
  nzWidth="90%"
  (nzOnCancel)="closeModalChangeUserInfo()"
>
  <ng-container *nzModalContent>
    <change-user-info></change-user-info>
  </ng-container>
</nz-modal>
