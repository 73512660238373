import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  finalize,
  Observable,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { UserHttpService } from './user-http.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { UserRoleModel } from '../../auth/models/user-role.model';
import { UserInfoModel } from 'src/app/auth/models/user-info.model';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private unsubscribe: Subscription[] = [];

  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private userHttpService: UserHttpService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);

    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  getAllUsers(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.userHttpService.getAllUsers().pipe(
      map((res: any) => {
        return res.map((item: any) => {
          return {
            id: item.id,
            code: item.id,
            name: item.name,
            role: item.role,
          };
        });
      }),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  findAllUser(params: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.userHttpService.findAll(params).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  update(payload: any) {
    this.isLoadingSubject.next(true);
    return this.userHttpService.updateUser(payload).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  changeInfo(payload: any) {
    this.isLoadingSubject.next(true);
    return this.userHttpService.changeInfo(payload).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  changeInfoAdmin(payload: any) {
    this.isLoadingSubject.next(true);
    return this.userHttpService.changeInfoAdmin(payload).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getUserById(id: any) {
    this.isLoadingSubject.next(true);
    return this.userHttpService.getUserById(id).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  create(payload: any) {
    this.isLoadingSubject.next(true);
    return this.userHttpService.createUser(payload).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  delete(id: any) {
    this.isLoadingSubject.next(true);
    return this.userHttpService.deleteUser(id).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getAllRoles(): Observable<UserRoleModel[]> {
    return this.userHttpService.getAllRoles();
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
