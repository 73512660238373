import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, finalize, Observable, of, Subscription, switchMap, throwError} from 'rxjs';
import {AppConstants} from '../../app-constants';
import {CategoryHttpService} from './category-http.service';
import {Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService implements OnDestroy {
  private unsubscribe: Subscription[] = [];

  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private categoryHttpService: CategoryHttpService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
   
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  findAllCategory(params: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.categoryHttpService.findAll(params).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  searchCategory(parentCodes: string[]): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.categoryHttpService.searchCategory(parentCodes).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  update(payload: any) {
    this.isLoadingSubject.next(true);
    return this.categoryHttpService.updateCategory(payload).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getCategoryById(id: any) {
    this.isLoadingSubject.next(true);
    return this.categoryHttpService.getCategoryById(id).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  create(payload: any) {
    this.isLoadingSubject.next(true);
    return this.categoryHttpService.createCategory(payload).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  delete(id: any) {
    this.isLoadingSubject.next(true);
    return this.categoryHttpService.deleteCategory(id).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
