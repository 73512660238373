import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, finalize, Observable, of, Subscription} from 'rxjs';
import { LocationHttpService } from './location-http.service';
import {Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService implements OnDestroy {
  private unsubscribe: Subscription[] = [];

  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private locationHttpService: LocationHttpService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
   
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  getProvince(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.locationHttpService.getProvinces().pipe(
      map((res: any) => {
        return res.map((item: any) => {
          return {
            id: item.id,
            code: item.provinceCode,
            name: item.name,
          };
        });
      }),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getDistrictsByProvinceCode(provinceCode: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.locationHttpService.getDistrictsByProvinceCode(provinceCode).pipe(
      map((res: any) => {
        return res.map((item: any) => {
          return {
            id: item.id,
            provinceCode: item.provinceCode,
            code: item.districtCode,
            name: item.name,
          };
        });
      }),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
