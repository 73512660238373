import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { first, Observable, Subscription } from 'rxjs';
import { UserModel } from 'src/app/auth/models/user.model';
import { AuthService } from 'src/app/auth/services';
@Component({
  selector: 'app-logout-sso-callback',
  template: '',
  styles: [''],
})
export class LogoutSSOCallback implements OnInit, OnDestroy {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  ngOnDestroy(): void {}
}
