import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

const API_LOCATION_URL = `${environment.apiUrl}/location`;

@Injectable({
  providedIn: 'root'
})
export class LocationHttpService {

  constructor(private http: HttpClient) {
  }

  getProvinces(): Observable<any[]> {
    return this.http.get<any[]>(`${API_LOCATION_URL}/province`);
  }

  getDistrictsByProvinceCode(provinceCode: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_LOCATION_URL}/district?provinceCode=${provinceCode}`);
  }
}
